import React from "react";

import fotoEdu from "../fotoEquipo/fotoEdu.jpeg";
import fotoFelipe from "../fotoEquipo/fotoFelipe.jpeg";
import fotoNacho from "../fotoEquipo/fotoNacho.jpeg";

function Empresa() {
  window.scrollTo(0, 0);
  return (
    <div id="Empresa">
      <div className="container ">
        <h2 className="empresa-titles"> Quienes Somos? </h2>
        <h4 className="empresa-titles mt-3">Identidad</h4>
        <p>
          Sanitech es una empresa orientada a brindar servicios de alta calidad
          , stock permanente, precio y garantías.
        </p>
        <p>
          Contamos con mas de 35 años de experiencia comercializando sistemas
          sanitarios en Argentina y Sudamérica.
        </p>
        <p>
          Sus socios fundadores Eduardo Fernández Cutiellos, Felipe de Posadas e
          Ignacio Guerra, quienes motivados por las necesidades del mercado
          volcaron todo su conocimiento en Sanitech.
        </p>
        <h4 className="empresa-titles mt-3">Misión</h4>
        <p>
          Nuestra misión es proveer los materiales sanitarios de mayor
          desarrollo y calidad, comprometidos a ofrecer un excelente servicio de
          posventa y garantías.
        </p>
        <h4 className="empresa-titles mt-3">Visión</h4>
        <p>
          Nuestra visión es convertirnos en una empresa referente en la
          distribución de materiales sanitarios en el Uruguay, siendo
          reconocidos por la calidad y el servicio.
        </p>

        <h3 className="empresa-titles mt-5">Equipo</h3>
        <div className="row align-items-center text-center mt-5">
          <div className="col-md-4">
            <img
              className="mb-3"
              style={{ width: "100%" }}
              src={fotoFelipe}
              alt=""
            />
            <h3>Felipe de Posadas</h3>
            <p style={{ fontSize: "1.25rem" }} className="mt-2">
              Dirección Comercial
            </p>
            <a href="mailto:felipe@sanitech.uy">
              <p className="empresa-telefonos my-0"> felipe@sanitech.uy</p>
            </a>
            <a href="https://api.whatsapp.com/send?phone=59895950506">
              <p className="empresa-telefonos">+598 95 950 506</p>
            </a>
          </div>
          <div className="col-md-4">
            <img
              className="mb-3"
              style={{ width: "100%" }}
              src={fotoEdu}
              alt=""
            />
            <h3>Eduardo Fernández Cutiellos</h3>
            <p style={{ fontSize: "1.25rem" }} className="mt-2">
              Dirección General{" "}
            </p>
            <a href="mailto:eduardo@sanitech.uy">
              <p className="empresa-telefonos my-0">eduardo@sanitech.uy</p>
            </a>
            <a href="https://api.whatsapp.com/send?phone=59894413736">
              <p className="empresa-telefonos">+598 94 413 736</p>
            </a>
          </div>
          <div className="col-md-4">
            <img
              className="mb-3"
              style={{ width: "100%" }}
              src={fotoNacho}
              alt=""
            />
            <h3>Ignacio Guerra</h3>
            <p style={{ fontSize: "1.25rem" }} className="mt-2">
              Dirección Operativa
            </p>
            <a href="mailto:ignacio@sanitech.uy">
              <p className="empresa-telefonos my-0">ignacio@sanitech.uy</p>
            </a>
            <a href="https://api.whatsapp.com/send?phone=59894066399">
              <p className="empresa-telefonos">+598 94 066 399</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Empresa;
