import React from "react";

function Asesoramiento() {
  window.scrollTo(0, 0);
  return (
    <div id="Asesoramiento">
      <div className="container">
        <div className="row">
          <h1 className="mb-4">ASESORAMIENTO, CÓMPUTO, POST-VENTA, GARANTÍA</h1>

          <h4>Asesoramiento en obra</h4>
          <p>
            Te podemos asesorar desde el diseño de tu proyecto, durante la obra
            y al finalizar la misma, visitándote en obra o en el estudio para
            analizar juntos cada uno de los detalles o necesidades de la
            instalación que permitan su perfecto funcionamiento, prolongada vida
            útil y excelencia en su instalación.
          </p>
          <h4>Computo de materiales</h4>
          <p>
            Para que puedas optimizar materiales, horas de trabajo y costos en
            general de tu proyecto, podemos enviarlo a fábrica, para realizar de
            manera gratuita el cálculo de materiales.”
          </p>
          <p>
            Enviar mail a Sanitech con AutoCAD para hacer despiece de planos y
            luego cotizar.
          </p>

          <p>
            Solicitar posventa a su obra enviando mail a felipe@sanitech.uy o
            clickeando el boton de whatsapp
          </p>
        </div>
      </div>
    </div>
  );
}

export default Asesoramiento;
