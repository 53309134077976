import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import brands from "../../brands.json";

function Carrousel() {
  const navigate = useNavigate();
  return (
    <div id="Carrousel">
      <Carousel fade>
        {brands.map((brand) => (
          <Carousel.Item key={brand.title}>
            <img
              className="d-block w-100"
              src={brand.largeImg}
              alt="First slide"
            />
            <div className="blackOut">
              <h1>{brand.title}</h1>
              <h4 className="px-5">{brand["sub-title"]}</h4>
              <button
                className="btn btn-success mt-3"
                onClick={() => navigate(`/productos/${brand.title}`)}
              >
                VER MÁS
              </button>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Carrousel;
