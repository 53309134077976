import React, { useState } from "react";
import { GoLocation } from "react-icons/go";
import { GoMail } from "react-icons/go";
import { AiFillPhone } from "react-icons/ai";
import Logo from "../img/logo.jpeg";
import { AiOutlineMenu } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import brands from "../brands.json";
import categories from "../categories.json";

function OffCanvasExample({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  return (
    <>
      <Button variant="primary" onClick={handleShow} className="me-2">
        <AiOutlineMenu className="burgerMenu" />
      </Button>
      <Offcanvas
        style={{ paddingRight: "20px" }}
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <img
            class="logoBurger"
            onClick={() => navigate("/")}
            src={Logo}
            alt=""
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="m-0 burgerList">
            <li
              onClick={() => {
                handleClose();
                navigate("/empresa");
              }}
            >
              EMPRESA
            </li>
            <hr />
            <li
              onClick={() => {
                handleClose();
                navigate("/obras");
              }}
            >
              OBRAS
            </li>
            <hr />
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                CATALOGO
              </button>
              <ul className="dropdown-menu">
                {brands.map((brand) => (
                  <li key={brand.title}>
                    <a
                      onClick={() => {
                        handleClose();
                        navigate(`/productos/${brand.title}`);
                      }}
                      className="dropdown-item"
                      href="#"
                    >
                      {brand.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <hr />
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                PRODUCTOS
              </button>
              <ul className="dropdown-menu">
                {categories.map((category) => (
                  <li key={category}>
                    <a
                      onClick={() => {
                        handleClose();
                        navigate(`/marcas/${category}`);
                      }}
                      className="dropdown-item"
                    >
                      {category}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <hr />
            <li
              onClick={() => {
                handleClose();
                navigate("/certificaciones");
              }}
            >
              CERTIFICACIONES
            </li>
            <hr />
            <li
              onClick={() => {
                handleClose();
                navigate("/contacto");
              }}
            >
              CONTACTO
            </li>
          </ul>
          <div className="location">
            <div className="container mt-5">
              <div className=" locationBurger">
                <p>
                  <GoLocation /> Gral. Julio A. Roletti 3524, Montevideo,
                  Uruguay.
                </p>
                <p>
                  <GoMail /> ventas@sanitech.uy
                </p>
                <p>
                  <AiFillPhone /> 095 950 506
                </p>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function Nav() {
  const navigate = useNavigate();
  return (
    <div id="Nav" className="d-flex align-items-center">
      <div className="container d-flex align-items-center justify-content-between">
        <img onClick={() => navigate("/")} src={Logo} alt="" />
        <OffCanvasExample placement={"end"} name={"end"} />
        <ul className="d-flex m-0">
          <li onClick={() => navigate("/empresa")}>EMPRESA</li>
          <li onClick={() => navigate("/obras")}>OBRAS</li>
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              CATALOGO
            </button>
            <ul className="dropdown-menu">
              {brands.map((brand) => (
                <li key={brand.title}>
                  <a
                    onClick={() => navigate(`/productos/${brand.title}`)}
                    className="dropdown-item"
                  >
                    {brand.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              PRODUCTOS
            </button>
            <ul className="dropdown-menu">
              {categories.map((category) => (
                <li key={category}>
                  <a
                    onClick={() => {
                      navigate(`/marcas/${category}`);
                    }}
                    className="dropdown-item"
                  >
                    {category}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <li onClick={() => navigate("/certificaciones")}>CERTIFICACIONES</li>
          <li onClick={() => navigate("/contacto")}>CONTACTO</li>
        </ul>
      </div>
      <div className="location">
        <div className="container justify-content-between d-flex">
          <p>
            <GoLocation /> Gral. Julio A. Roletti 3524
          </p>
          <p>
            <GoMail /> ventas@sanitech.uy
          </p>
          <p>
            <AiFillPhone /> 095 950 506
          </p>
        </div>
      </div>
    </div>
  );
}

export default Nav;
