import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import products from "../../products.json";
import { useNavigate, useParams } from "react-router-dom";

const responsive = {
  // the naming can be any, depends on you.
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function CarrouselFeatured() {
  const navigate = useNavigate();
  return (
    <div id="CarrouselFeatured">
      <div className="container my-5 ">
        <Carousel responsive={responsive}>
          {products
            .filter((item) => item.important === true)
            .map((featuredProduct) => (
              <div
                key={featuredProduct.id}
                onClick={() => navigate(`/product/${featuredProduct.id}`)}
                className="featuredProducts"
              >
                <img
                  className="imgFeaturedProducts"
                  src={featuredProduct.image}
                />
                <p className="my-2"> {featuredProduct.name}</p>
              </div>
            ))}
        </Carousel>
      </div>
    </div>
  );
}

export default CarrouselFeatured;
