import "./styles/Manu.css";
import "./styles/Juan.css";
import "./styles/All.css";

import Nav from "./components/Nav";
import Footer from "./components/Footer";

import Logo from "./img/logo.jpeg";

import ProductView from "./components/ProductView";
import Landing from "./components/Landing/Landing";
import BrandProducts from "./components/BrandProducts";
import Marcas from "./components/Marcas";
import Empresa from "./components/Empresa";
import Contacto from "./components/Contacto";
import Obras from "./components/Obras";
import Certificaciones from "./components/Certificaciones";
import Asesoramiento from "./components/Asesoramiento";

import AOS from "aos";

import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";

function App() {
  const [soon, setSoon] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: "ease-in-sine",
    });
  }, []);
  return (
    <div>
      {soon && (
        <div className="soon">
          <img src={Logo} alt="" />
          <h3 className="mt-2">COMING SOON</h3>
        </div>
      )}
      <a
        href="https://api.whatsapp.com/send?phone=59895950506&text=Hola!"
        class="float"
        target="_blank"
      >
        <i class="fa fa-whatsapp my-float"></i>
      </a>
      <Nav />
      <div
        className="carrouselTopMargin"
        style={{ marginBottom: "13vh" }}
      ></div>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/marcas/:categorySlug" element={<Marcas />} />
        <Route path="/productos/:brandSlug" element={<BrandProducts />} />
        <Route path="/product/:productId" element={<ProductView />} />
        <Route path="/obras" element={<Obras />} />
        <Route path="/empresa" element={<Empresa />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/certificaciones" element={<Certificaciones />} />
        <Route path="/asesoramiento" element={<Asesoramiento />} />
        <Route path="*" element={<h1 className="text-center">404</h1>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
