import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

function Videos() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <Carousel interval={null}>
        <Carousel.Item>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
            className="container"
          >
            <iframe
              style={{ width: "80%", height: "400px" }}
              src="https://www.youtube.com/embed/obC2mlrfmow"
              frameborder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
            className="container"
          >
            <iframe
              style={{ width: "80%", height: "400px" }}
              src="https://www.youtube.com/embed/GH5lnl555sU"
              frameborder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
            className="container"
          >
            <iframe
              style={{ width: "80%", height: "400px" }}
              src="https://www.youtube.com/embed/gbpx33JrvwI"
              frameborder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
            className="container"
          >
            <iframe
              style={{ width: "80%", height: "400px" }}
              src="https://www.youtube.com/embed/-6Ru5jPg6Hw"
              frameborder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
            className="container"
          >
            <iframe
              style={{ width: "80%", height: "400px" }}
              src="https://www.youtube.com/embed/0LKRpCx5eXg"
              frameborder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
            className="container"
          >
            <iframe
              style={{ width: "80%", height: "400px" }}
              src="https://www.youtube.com/embed/mSgZyY-J6TM"
              frameborder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
            className="container"
          >
            <iframe
              style={{ width: "80%", height: "400px" }}
              src="https://www.youtube.com/embed/v3F-g-20z3o"
              frameborder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
            className="container"
          >
            <iframe
              style={{ width: "80%", height: "400px" }}
              src="https://www.youtube.com/embed/WIRpSg6qh7o"
              frameborder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "center",
            }}
            className="container"
          >
            <iframe
              style={{ width: "80%", height: "400px" }}
              src="https://www.youtube.com/embed/JEg7beyctFw"
              frameborder="0"
              allowFullScreen="allowFullScreen"
            ></iframe>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Videos;
