import React from "react";

import Ezeiza from "../fotosobras/obra1.jpg";
import EstadioEstudiantes from "../fotosobras/obra2.jpg";
import FacultadIngennieria from "../fotosobras/obra3.jpg";
import Fragata from "../fotosobras/obra4.jpg";
import HospitalGutierrez from "../fotosobras/obra5.jpg";
import Hilton from "../fotosobras/obra6.jpg";
import HotelMarriot from "../fotosobras/obra7.jpg";
import HotelLlaoLlao from "../fotosobras/obra8.jpg";
import MaderoHarbour from "../fotosobras/obra9.jpg";
import PlantaAcitera from "../fotosobras/obra10.jpg";
import ShoppingLasPalmas from "../fotosobras/obra11.png";
import SupermercadoPalermo from "../fotosobras/obra12.jpg";
import TeatroColon from "../fotosobras/obra13.jpg";
import TemploMormon from "../fotosobras/obra14.webp";
import TorreYPF from "../fotosobras/obra17.jpg";

import Obrauru1 from "../fotosobrasUruguay/obrauru1.jpeg";
import Obrauru2 from "../fotosobrasUruguay/obrauru2.jpeg";
import Obrauru3 from "../fotosobrasUruguay/obrauru3.jpeg";

/* La saque para que quede bien */
import Obrauru4 from "../fotosobrasUruguay/obrauru4.jpeg";

import Obrauru5 from "../fotosobrasUruguay/obrauru5.jpeg";
import Obrauru6 from "../fotosobrasUruguay/obrauru6.jpeg";
import Obrauru7 from "../fotosobrasUruguay/obrauru7.jpeg";
import Obrauru8 from "../fotosobrasUruguay/obrauru8.jpeg";
import Obrauru9 from "../fotosobrasUruguay/obrauru9.jpeg";

const obras = [
  {
    url: Ezeiza,
    title: "Aeropuerto de Ezeiza",
  },
  {
    url: TeatroColon,
    title: "Teatro Colon",
  },

  {
    url: HospitalGutierrez,
    title: "Hospital Gutierrez",
  },
  {
    url: Hilton,
    title: "Hilton",
  },
  {
    url: "https://www.mendiorozdesign.com/images/portfolio/bbva/c_01.jpg",
    title: "Torre BBVA",
  },
  {
    url: FacultadIngennieria,
    title: "Facultad de Ingeniería",
  },
  {
    url: HotelLlaoLlao,
    title: "Hotel Llao Llao",
  },
  {
    url: Fragata,
    title: "Fragata Libertad",
  },
  {
    url: PlantaAcitera,
    title: "Planta Aceitera Gral Deheza",
  },
  {
    url: EstadioEstudiantes,
    title: "Estadio Estudiantes de la Plata",
  },
  {
    url: SupermercadoPalermo,
    title: "Supermercado Palermo",
  },
  {
    url: ShoppingLasPalmas,
    title: "Shopping Las Palmas",
  },
  {
    url: TemploMormon,
    title: "Templo Mormon",
  },
  {
    url: TorreYPF,
    title: "Torre YPF",
  },
  {
    url: MaderoHarbour,
    title: "Harbour Tower",
    vertical: true,
  },
  {
    url: HotelMarriot,
    title: "Hotel Marriot",
    vertical: true,
  },
  {
    url: Obrauru1,
    title: "",
    vertical: true,
  },
  {
    url: Obrauru2,
    title: "",
    vertical: true,
  },
  {
    url: Obrauru5,
    title: "",
  },
  {
    url: Obrauru6,
    title: "",
  },
  {
    url: Obrauru7,
    title: "",
    vertical: true,
  },
  {
    url: Obrauru8,
    title: "",
    vertical: true,
  },
  {
    url: Obrauru9,
    title: "",
    vertical: true,
  },
  {
    url: Obrauru3,
    title: "",
    vertical: true,
  },
];

function Obras() {
  window.scrollTo(0, 0);
  return (
    <div id="Obras">
      <div className="container py-5">
        <h2 className="obras-title">Obras</h2>
        <div className="row">
          {obras.map((obra) => (
            <div className="col-md-6 my-2">
              <div className="position-relative">
                <img
                  style={
                    !obra.vertical
                      ? {
                          width: "100%",
                          objectFit: "cover",
                          height: "400px",
                        }
                      : {
                          width: "100%",
                          objectFit: "cover",
                          height: "700px",
                        }
                  }
                  src={obra.url}
                  alt=""
                />
                <div className="obraBlackOut">
                  <h3>{obra.title}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Obras;
