import { React } from "react";
import { useNavigate } from "react-router-dom";

import ObraLanding1 from "../../img/Picture1.png";
import ObraLanding2 from "../../img/Picture2.png";
import Catalogo from "../../catalogo.pdf";

function ObrasLanding() {
  const navigate = useNavigate();

  return (
    <div id="ObrasLanding">
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="catalogoCard">
            <img src="https://www.industriassaladillo.com.ar/assets/img/products/agua/hidro3/img-ppal.jpg" />
            <div className="container pt-2 mb-2">
              <p>HIDRO 3</p>
              <p>Una categoría aparte en el manejo de agua.</p>
            </div>
            <a
              target="_blank"
              href="https://www.industriassaladillo.com.ar/downloads/hidro3.pdf"
            >
              <button className="btn btn-success">VER MANUAL</button>
            </a>
          </div>
          <div className="catalogoCard">
            <img src="https://www.industriassaladillo.com.ar/assets/img/home/ext/catalogo-productos.jpg" />
            <div className="container pt-2 mb-2">
              <p>CATALOGO DE PRODUCTOS</p>
              <p>
                Descargá desde aquí la última versión del catálogo actualizado
                para todos los productos de Industrias Saladillo
              </p>
            </div>
            <a href={Catalogo} download>
              <button className="btn btn-success">DESCARGAR</button>
            </a>
          </div>
          <div className="catalogoCard">
            <img src="https://www.industriassaladillo.com.ar/assets/img/products/desagues/awaduct/img-ppal.jpg" />
            <div className="container pt-2 mb-2">
              <p>AWADUCT</p>
              <p>Más soluciones. Un mismo sistema.</p>
            </div>
            <a
              href="https://www.industriassaladillo.com.ar/downloads/awaduct.pdf"
              target="_blank"
            >
              <button className="btn btn-success">VER MANUAL</button>
            </a>
          </div>
        </div>
        <hr />
        <h3 className="d-flex tituloObras mt-5"> Obras referenciales </h3>
        <div className="row my-5 obraUno">
          <div data-aos="fade-down" className="col-md-6 mt-4">
            <img
              data-aos="fade-up"
              style={{ width: "100%", height: "420px" }}
              src={ObraLanding1}
              alt="Facultad de Ingeniería"
            />
          </div>
          <div className="col-md-6 d-flex justify-content-center mt-4">
            <img
              data-aos="fade-up"
              style={{ width: "100%", height: "420px" }}
              src={ObraLanding2}
              alt="Aeropuerto Ezeiza"
            />
          </div>
        </div>
        <hr />

        <div className="d-flex justify-content-center ">
          <button
            type="button"
            onClick={() => navigate("/obras")}
            className="btn btn-success btn-lg my-5"
          >
            Ver Todas las Obras
          </button>
        </div>
      </div>
    </div>
  );
}

export default ObrasLanding;
