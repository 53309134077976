import React from "react";

function Certificaciones() {
  window.scrollTo(0, 0);
  return (
    <div id="Certificaciones">
      <img
        src="https://www.industriassaladillo.com.ar/assets/img/bg/calidad-bg.jpg"
        className="certiImg"
      />
      <div className="container mt-3 p-5">
        <h3 className="text-center mb-3">Certificaciones ISO 9001</h3>
        <div className="row">
          <div className="mt-3 p-3 col-md-6 d-flex justify-content-center">
            <img
              onClick={() =>
                window.open(
                  "https://www.industriassaladillo.com.ar/downloads/calidad/iram-full.pdf"
                )
              }
              src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/iso-9001/iram.png"
            />
          </div>
          <div className="mt-3 p-3 col-md-6 d-flex justify-content-center">
            <img
              onClick={() =>
                window.open(
                  "https://www.industriassaladillo.com.ar/downloads/calidad/iqnet-full.pdf"
                )
              }
              src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/iso-9001/iqnet.png"
            />
          </div>
        </div>
        <hr />

        <div id="certificaciones-productos" className="row">
          <h3 className="text-center mt-3">Certificaciones De Producto</h3>
          <p>
            En Industrias Saladillo queremos ofrecer siempre un producto de la
            más alta calidad, y es por eso que certificamos nuestras familias de
            producto según las normas más estrictas para cada uso.
          </p>
        </div>

        <div className="row my-5">
          <div className="d-flex">
            <div>
              <img
                className="categoryImage"
                src="https://www.industriassaladillo.com.ar/assets/img/icon/calidad/lineas-producto/agua.png"
                alt=""
              />
            </div>
            <div style={{ width: "100%" }} className="ps-3">
              <h6> AGUA </h6>
              <hr />
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/agua/certificado-agua.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p>
                    {" "}
                    Licencia IRAM Hidro3 en conformidad con IS-01 de 1-2 a 2
                    unión por rosca
                  </p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-hidro3-rosca.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3  ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/agua/certificado-agua.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p>
                    {" "}
                    Licencia Hidro3 hasta 2” en conformidad con ISO 21003 (parte
                    5) SISTEMA
                  </p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-hidro3-sistema.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="d-flex">
            <div>
              <img
                className="categoryImage"
                src="https://www.industriassaladillo.com.ar/assets/img/icon/calidad/lineas-producto/desagues.png"
                alt=""
              />
            </div>
            <div style={{ width: "100%" }} className="ps-3">
              <h6> DESAGUES </h6>
              <hr />
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/desagues/certificado-desagues-1.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificado TUV accesorios Awaduct</p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-accesorios-awaduct.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/desagues/certificado-desagues-2.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificado TUV accesorios Terra</p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-accesorios-terra.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/desagues/certificado-desagues-2.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificado TUV accesorios Terra</p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-accesorios-terra.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/desagues/certificado-desagues-2.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificado TUV accesorios Terra</p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-accesorios-terra.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-5">
          <div className="d-flex">
            <div>
              <img
                className="categoryImage"
                src="https://www.industriassaladillo.com.ar/assets/img/icon/calidad/lineas-producto/gas.png"
                alt=""
              />
            </div>
            <div style={{ width: "100%" }} className="ps-3">
              <h6> GAS </h6>
              <hr />
            </div>
          </div>
          <div className="  col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className=" cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/ext/gas/certificado-gas-1.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p>
                    {" "}
                    Certificado IBNORCA de conformidad con NB 1216020 Sistema
                  </p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-maygas-ibnorca-sistema.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/ext/gas/certificado-gas-2.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificado IRAM por ISO 17484-1 (sistema)</p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-maygas-iram-sistema.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/ext/gas/certificado-gas-3.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p>
                    {" "}
                    Certificado IRAM por MAYGAS en conformidad EN 331 (válvulas)
                  </p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-maygas-iram-valvulas.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/ext/gas/certificado-gas-4.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p>
                    {" "}
                    Certificado produto MAYGAS em comformidade com ISO 17484-1
                    pela ABNT
                  </p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-maygas-brasil.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/ext/gas/certificado-gas-3.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificados CNCP para tubos MAYGAS</p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-maygas-cncp-tubos.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/ext/gas/certificado-gas-3.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificados CNCP para conectores PE MAYGAS </p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-maygas-cncp-conectores-pe.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/ext/gas/certificado-gas-3.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificados CNCP para conectores Rosca MAYGAS</p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-maygas-cncp-conectores-rosca.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/ext/gas/certificado-gas-6.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificado ICONTEC NTC 6015 (Sistema) </p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-maygas-icontec-colombia.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 mt-4">
            <div className="cadaCertificacion container p-3 ">
              <div className="row">
                <div className="col-md-3 imgCertificacion">
                  <img
                    src="https://www.industriassaladillo.com.ar/assets/img/calidad/certificaciones/thumbs/productos/ext/gas/certificado-gas-6.jpg"
                    alt=""
                  />
                </div>
                <div className="col-md-9 px-5 infoCertificacion ">
                  <p> Certificado ICONTEC NTC 6015 (Anexo) </p>

                  <a
                    style={{ textDecoration: "none" }}
                    href="https://www.industriassaladillo.com.ar/downloads/calidad/certificado-maygas-icontec-colombia-anexo.pdf"
                  >
                    {" "}
                    Ver Certificacion Online
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certificaciones;
