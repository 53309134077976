import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import categories from "../categories.json";

import brands from "../brands.json";
import products from "../products.json";

function BrandProducts() {
  const navigate = useNavigate();
  const { brandSlug } = useParams();
  window.scrollTo(0, 0);
  const brand = brands.find((item) => item.title === brandSlug);
  return (
    <div id="BrandProducts">
      <div className="container">
        <img src={brand.largeImg} className="brandImgPres" />
        <div className="blackOut">
          <h1>{brand.title}</h1>
          <h4>{brand["sub-title"]}</h4>
        </div>
        <div style={{ marginTop: "450px" }} className="row">
          <div className="col-md-3">
            <div className="sidebarCategory mb-5">
              <h4 className="mb-3"> Categorias </h4>
              <ul>
                {categories.map((item) => (
                  <li
                    key={item}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/marcas/${item}`)}
                    className="mb-2"
                  >
                    {" "}
                    {item}{" "}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              {products
                .filter(
                  (item) => item.BrandSlug === brandSlug.toLocaleLowerCase()
                )
                .map((product) => (
                  <div
                    key={product.name}
                    onClick={() => navigate(`/product/${product.id}`)}
                    className="col-md-4 col-6 d-flex flex-column align-items-center"
                    style={{ padding: "25px" }}
                  >
                    <img className="imgCategorias" src={product.image} alt="" />
                    <div className="mt-3" style={{ width: "80%" }}>
                      <p style={{ overflow: "scroll" }} className="mb-1">
                        {product.name}
                      </p>
                      <p
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: "thin",
                          fontSize: "0.75rem",
                          color: "grey",
                        }}
                      >
                        Codigo: {product.id}
                      </p>
                    </div>
                  </div>
                ))}
              {/* Hay que poner que el catalogo sea el de cada uno */}{" "}
              <div className="d-flex my-4 justify-content-center">
                <button
                  onClick={() => window.open(brand.manual)}
                  type="button"
                  className="btn btn-success my-3"
                >
                  Ver Catalogo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandProducts;
