import React from "react";
import Carrousel from "./Carrousel";
import CarrouselFeatured from "./CarrouselFeatured";
import ObrasLanding from "./ObrasLanding";
import Servicios from "./Servicios";
import Videos from "./Videos";

function Landing() {
  return (
    <div>
      <Carrousel />
      <ObrasLanding />
      <Servicios />
      <Videos />
      <CarrouselFeatured />
    </div>
  );
}

export default Landing;
