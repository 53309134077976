import React from "react";

function Contacto() {
  window.scrollTo(0, 0);
  return (
    <div id="Contacto">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {/* <form className="contactForm">
              <h3>Contactanos</h3>
              <input required type="text" name="name" placeholder="Nombre" />
              <input required type="email" name="email" placeholder="Email" />
              <input required type="text" name="phone" placeholder="Celular" />
              <textarea
                required
                name="message"
                placeholder="Esribenos tu consulta"
              ></textarea>
              <button type="submit">Enviar</button>
            </form> */}
            <form className="contactForm" name="contact" method="post">
              <input type="hidden" name="form-name" value="contact" />
              <input
                placeholder="Nombre"
                type="text"
                id="name"
                name="name"
                required
              />
              <input
                placeholder="Email"
                type="email"
                id="email"
                name="email"
                required
              />
              <textarea
                placeholder="Cuentanos que necesitas"
                id="message"
                name="message"
                required
              ></textarea>
              <input type="submit" value="Enviar" />
            </form>
          </div>
          <div className="col-md-6">
            <iframe
              style={{ width: "100%", height: "100%" }}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3273.308325260318!2d-56.15121648458409!3d-34.873603179335305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f80f2b86bd38d%3A0x79076b58e8fe2d6f!2sGral.%20Julio%20A.%20Roletti%203524%2C%2012000%20Montevideo%2C%20Departamento%20de%20Montevideo!5e0!3m2!1ses!2suy!4v1660188689618!5m2!1ses!2suy"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacto;
