import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import products from "../products.json";

function ProductView() {
  window.scrollTo(0, 0);
  const [size, setSize] = useState();
  const { productId } = useParams();
  const [product, setProduct] = useState();
  const [variants, setVariants] = useState([]);
  const [PrecioEnMetros, setPrecioEnMetros] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setProduct(products.filter((item) => item.id === productId)[0]);
    setVariants(
      products
        .filter((item) => item.id === productId)[0]
        .variants.replace("[", "")
        .replace("]", "")
        .split(",")
    );
  }, []);

  return (
    <div id="ProductView">
      {product && variants && (
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="p-0 d-flex imgProductView ">
                <img
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "70vh",
                  }}
                  className=""
                  src={product.image}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6 productDetailsBox">
              <div className="productDetails">
                <h2>{product.name}</h2>
                <h6 style={{ fontFamily: "lato-light" }}>
                  Codigo: {product.id}
                </h6>

                <hr />
                <div className="medidasProduct d-flex flex-column mb-5">
                  <p style={{ fontFamily: "lato-light" }}>MEDIDAS: </p>
                  <select
                    defaultValue=""
                    onChange={(e) => setSize(e.target.value)}
                    className="productSelect"
                  >
                    <option value="" disabled>
                      Selecciona una medida
                    </option>
                    {variants.map((item) => (
                      <option key={item} value={item}>
                        {item.split("-")[0]}
                        {product.BrandSlug === "hidro 3" ? '"' : "mm"} - U$S
                        {product.BrandSlug === "awaduct" || "acustik" || "terra"
                          ? Math.round(item.split("-")[1] * 1.188 * 100) / 100
                          : Math.round(item.split("-")[1] * 1.1 * 100) / 100}
                      </option>
                    ))}
                  </select>
                  <h6 className="mt-3 " style={{ fontFamily: "lato-light" }}>
                    {product.PrecioEnMetros
                      ? "Precio por metro, caño es de 6m"
                      : ""}
                  </h6>
                  <button
                    onClick={() => {
                      if (size) {
                        window.open(
                          `https://wa.me/+59895950506/?text=Hola, Estoy interesado en el producto: ${product.name} - codigo: ${product.id}. Tienen stock disponible con medida ${size}`
                        );
                      }
                    }}
                    className="product-btn"
                  >
                    Comprar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductView;
