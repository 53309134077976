import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import brands from "../brands.json";

function Marcas() {
  const { categorySlug } = useParams();
  const navigate = useNavigate();
  window.scrollTo(0, 0);
  return (
    <div id="Marcas">
      <div className="container">
        <h1 className="text-center pt-3">{categorySlug.toUpperCase()}</h1>
        {brands
          .filter((item) => item.category === categorySlug)
          .map((brand, index) => (
            <div
              key={brand.title}
              className="row my-5 cadaMarca"
              style={index % 2 === 0 ? { backgroundColor: "" } : {}}
            >
              <div className="col-md-6 fotoMarcas">
                <img
                  className="imgMarcas"
                  src={brand.img}
                  style={
                    brand.title == "Maygas"
                      ? { objectFit: "contain", width: "100%" }
                      : { objectFit: "contain" }
                  }
                />
              </div>
              <div className="col-md-6 infoMarca">
                <h2 className="tituloMarca"> {brand.title}</h2>
                <p className="subtituloMarca">{brand["sub-title"]}</p>
                <p className="descripcionMarca"> {brand.desc}</p>

                <div className="botonesMarcas">
                  <button
                    onClick={() => window.open(brand.manual)}
                    type="button"
                    className="btn btn-success my-3 me-4"
                  >
                    Ver Manual
                  </button>
                  <button
                    onClick={() => navigate(`/productos/${brand.title}`)}
                    type="button"
                    className="btn btn-secondary"
                  >
                    Ver Productos
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Marcas;
