import React from "react";

import { useNavigate } from "react-router-dom";

import { AiFillPhone } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { GoMail } from "react-icons/go";

import { BsCameraVideo } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";

function Footer() {
  const navigate = useNavigate();
  return (
    <div id="Footer">
      <div className="footer">
        <div className="container footer">
          <div className="row d-flex justify-content-between">
            <div className=" footerCols col-md-3 p-2">
              <h5 className="my-3">Contacto</h5>
              <ul className="nav d-flex" style={{ flexDirection: "column" }}>
                <li className="nav-item my-1">
                  <GoLocation /> Montevideo, Julio A.Roletti 3524
                </li>
                <li className="nav-item my-2">
                  <GoMail /> ventas@sanitech.uy
                </li>
                <li className="nav-item my-1">
                  <AiFillPhone /> +598 99 123 412
                </li>
              </ul>
              <ul className="nav d-flex" style={{ flexDirection: "column" }}>
                <li className="nav-item my-1">
                  <GoLocation /> Maldonado, Salsipuedes (Entre Cacique Brown y C. Tacuabé)
                </li>
                <li className="nav-item my-2">
                  <GoMail /> ventas@sanitech.uy
                </li>
                <li className="nav-item my-1">
                  <AiFillPhone /> +598 99 123 412
                </li>
              </ul>
            </div>
            <div className="footerCols paises col-md-2 p-2">
              <h5 className="my-3">Países</h5>
              <ul className="nav d-flex flex-column">
                <li className="nav-item">
                  <p>Uruguay 🇺🇾</p>
                </li>
                <li className="nav-item">
                  <p>Argentina 🇦🇷</p>
                </li>
              </ul>
            </div>
            <div className="footerCols col-md-3 p-2 ">
              <h5 className="my-3">Encontranos aca!</h5>
              <div className="d-flex">
                <a
                  style={{ color: "white" }}
                  className="me-3"
                  href="https://www.instagram.com/sanitech.uy"
                >
                  <BsInstagram size={30} />
                </a>
                <a
                  style={{ color: "white" }}
                  href="https://www.facebook.com/people/Sanitech/100078788898719/?sk=about"
                >
                  <FaFacebook size={30} />
                </a>
              </div>
            </div>
            <div className="footerCols col-md-4 p-2 mt-5">
              <div className="d-flex">
                <button className="btn btn-dark" type="button">
                  <BsCameraVideo
                    size={20}
                    style={{
                      marginRight: "10px",
                      position: "relative",
                      top: "-2px",
                    }}
                  />
                  <a
                    href="https://www.industriassaladillo.com.ar/capacitate/index.php"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    INSCRIPCIÓN A CAPACITACIONES VIRTUALES
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#008458" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "50px 0",
          }}
        >
          <a target="_blank" href="https://ouratec.com/">
            <img
              style={{ width: "200px", height: "200px" }}
              src="https://i.ibb.co/0qhtHZN/logo.png"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
