import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function Servicios() {
  const navigate = useNavigate();
  return (
    <div id="Servicios" style={{ backgroundColor: "#eaeaea" }}>
      <div className="container">
        <div className="row my-5 d-flex py-5">
          <div
            className="col-md-6 d-flex justify-content-center"
            data-aos="fade-down"
          >
            <img
              data-aos="fade-down"
              style={{ width: "100%", objectFit: "contain" }}
              src="https://www.industriassaladillo.com.ar/assets/img/home/arg/technik/img-technik.jpg"
              alt=""
            />
          </div>
          <div className="col-md-6 servicioColumn">
            <h2 className="my-4">
              {" "}
              ASESORAMIENTO, CÓMPUTO, POST-VENTA, GARANTÍA
            </h2>

            <p>
              Todo el respaldo que necesitás, como solo Sanitech puede darte.
              Esta división esta , encargada de brindar todo el soporte
              necesario antes, durante y después de la realización de tu obra,
              para asegurar el perfecto funcionamiento y la prolongada vida útil
              de las instalaciones de distribución de agua, desagüe, gas y
              calefacción.
            </p>
            <hr />
            <div style={{ color: "#008457" }}>
              <p> Asesoramiento en Obra </p>
              <p> Computo de Materiales </p>
            </div>
            <div className="d-flex justify-content-center ">
              <button
                onClick={() => navigate("/asesoramiento")}
                type="button"
                className="btn btn-success btn-lg my-5 "
              >
                Mas Información
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servicios;
